const windowHasFetch = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (typeof window.fetch === 'undefined') {
    return false;
  }
  return true;
};

export default windowHasFetch;
